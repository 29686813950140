<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <div class="row">
            <div class="col-md-12">
                <AMonthPicker
                    class="mr-2 mt-2"
                    v-model:value="state.params.month"
                    placeholder="Pilih Bulan"
                    style="width:300px"
                    format="MMMM YYYY"/>
                <FilterDistributor
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.distributor"
                    v-model:region="state.params.region"
                    v-model:area="state.params.area"
                    v-model:kabupaten="state.params.kabupaten"/>
                <FilterBrand
                    class="mr-2"
                    v-model:value="state.params.brand"/>
                <FilterRegional
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.region"/>
            </div>
            <div class="col-md-12 mt-2">
                <FilterArea
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.area"/>
                <!-- <FilterKabupatenKota
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.kabupaten"
                    v-model:region="state.params.region"
                    v-model:area="state.params.area"/> -->
                <AInputSearch
                    class="mr-2"
                    v-model:value="state.params.q"
                    placeholder="Cari..."
                    style="width:300px"/>
                <AButton
                    class="mr-2"
                    type="primary"
                    title="cari"
                    @click="btnFetchData()"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </div>
        </div>

        <div class="row justify-content-end">
            <div class="col-lg-6 col-md-12 mt-auto">
            </div>
            <div class="col-lg-6 col-md-12 text-right mt-auto">
                <DownloadExcel
                    :url="state.endpoint"
                    :params="state.params"
                    namefile="Laporan-Toko-Aktif-LT"
                    @errors="errorMessage" />
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">
            </MdTable>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, watch, onBeforeMount } from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterArea from '@/components/filter/FilterArea'
import FilterBrand from '@/components/filter/FilterBrand'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import { debounce, pickBy } from 'lodash'
import moment from 'moment'

export default defineComponent({
    components: {
        FilterDistributor,
        FilterRegional,
        FilterArea,
        FilterBrand,
        DownloadExcel,
    },
    setup() {
        const errorMessage = ref()

        const state = reactive({
            columns: [
                {
                    title: 'Tanggal',
                    dataIndex: 'Tanggal',
                },
                {
                    title: 'ID Toko',
                    dataIndex: 'Toko_ID',
                },
                {
                    title: 'Nama Toko',
                    dataIndex: 'Nama_Toko',
                },
                {
                    title: 'Alamat Toko',
                    dataIndex: 'Alamat_Toko',
                },
                {
                    title: 'Kabupaten Toko',
                    dataIndex: 'Kabupaten_Toko',
                },
                {
                    title: 'Provinsi Toko',
                    dataIndex: 'Provinsi_Toko',
                },
                {
                    title: 'Regional Toko',
                    dataIndex: 'Toko_Regional',
                },
                {
                    title: 'Area Toko',
                    dataIndex: 'Toko_Area',
                },
                {
                    title: 'Brands',
                    dataIndex: 'Brands',
                },
                {
                    title: 'Kode Produk',
                    dataIndex: 'Kode_Produk',
                },
                {
                    title: 'Nama Produk',
                    dataIndex: 'Nama_Produk',
                },
                {
                    title: 'QTY Zak',
                    dataIndex: 'Volume_ZAK',
                },
                {
                    title: 'QTY Ton',
                    dataIndex: 'Volume_TON',
                },
                {
                    title: 'Kode Gudang',
                    dataIndex: 'Warehouse_Code',
                },
                {
                    title: 'Nama Gudang',
                    dataIndex: 'Warehouse_Name',
                },
                {
                    title: 'Regional Gudang',
                    dataIndex: 'Warehouse_Regional',
                },
                {
                    title: 'Provinsi Gudang',
                    dataIndex: 'Warehouse_Provinsi',
                },
                {
                    title: 'Area Gudang',
                    dataIndex: 'Warehouse_Area',
                },
                {
                    title: 'Kabupaten Gudang',
                    dataIndex: 'Warehouse_Kota_Kabupaten',
                },
                {
                    title: 'Kode Distributor',
                    dataIndex: 'Distributor_ID',
                },
                {
                    title: 'Nama Distributor',
                    dataIndex: 'Distributor_Name',
                },
                {
                    title: 'Tipe Customer',
                    dataIndex: 'Customer_Type',
                },
                {
                    title: 'Cluster Pareto',
                    dataIndex: 'Cluster',
                },
            ],
            endpoint: '/api/report/laporan-lt/get-raw-data',
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            params: {
                q: '',
                region: [],
                distributor: [],
                area: [],
                kabupaten: [],
                brand: [],
                month: moment(new Date()).startOf('month').format('YYYY-MM'),
                page: 1,
                "per-page": 10,
            },
        })

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize
            
            btnFetchData()
        }

        const queryParams = () => {
            let params = state.params

            if (state.params.month) {
                params.month = moment(state.params.month).format('YYYY-MM')
            }

            return pickBy(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            apiClient
                .get(state.endpoint, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        const btnFetchData = () => {
            fetchDataList()
        }

        // handle vue
        onMounted(() => {
            btnFetchData()
        })

        return {
            btnFetchData,
            state,
            handleTableChange,
            errorMessage,
        }
    },
})
</script>

<style lang="scss" scoped>
.card {
    height: 100%;
    .card-body {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}
</style>